import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener';

import userRoles from '../globals/userRoles';

import AppLayout from './AppLayout/AppLayout';
import IndexRedirectHandler from '../routes/IndexRedirectHandler';
import NotFoundView from '../views/errors/NotFoundView';

// List Pages
import MySchoolPage from '../routes/MySchool/MySchool';
import ClassroomPage from '../routes/ClassroomPage/ClassroomPage';
import MyProfilePage from '../routes/MyProfile/MyProfile';

import UserProfileInit from '../routes/UserProfile/UserProfileInitialising';

import CreateOrganisation from '../routes/CreateOrganisation/CreateOrganisation';

// import isRegion from '../utils/isRegion';

// Auth pages
import RegisterPage from '../routes/Register/RegisterPage';
import RequestPasswordReset from '../routes/RequestPasswordReset/RequestPasswordReset';
import ResetPassword from '../routes/ResetPassword/ResetPassword';
import InvitationUpdatePage from '../routes/Register/Invites/InvitationUpdatePage';

// Redirect to Auth0
import LoginPage from '../routes/LoginPage/LoginPage';

// Support pages
import SupportHomePage from '../routes/Support/SupportHomePage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';

// ORB pages
import ORBLayout from './ORBLayout/ORBLayout';
import OrbClassReportPage from '../routes/development/ORB/OrbClassReportPage';
import OrbTeacherResourcesPage from '../pages/OrbTeacherResourcesPage';
import OrbOfflineAppPage from '../pages/OrbOfflineAppPage';
import OrbTeacherContactPreferencesPage from '../pages/OrbTeacherContactPreferencesPage/OrbTeacherContactPreferencesPage';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';

// OALD Redeem-code pages
import RedeemCodePage from '../routes/RedeemCode/RedeemCodePage';
import RedeemCodeSuccessPage from '../routes/RedeemCode/RedeemCodeSuccessPage';

import StudentAnswerViewPage from '../routes/Gradebook/StudentAnswerViewPage';

import protectedRoute from './protectedRoute';
import withAuthorization from '../authorization/withAuthorization';
import { limitToOrgRoles } from '../redux/selectors/authorization/organization';

import { LegacyRegisterOrgPage } from '../pages';

import DownloadSigninCardProcess from '../components/DownloadSigninCardProcess/DownloadSigninCardProcess';
// show invitations
import ConnectedBannerInvitation from '../components/BannerInvitation/ConnectedBannerInvitation';

// Decline Org Invitation from Email
import DeclineOrgInvitation from '../routes/DeclineOrgInvitation/DeclineOrgInvitation';
import OrbRegionDemoPage from '../routes/development/ORB/OrbRegionDemoPage';
import OrbRegionDemoRoot from '../routes/development/ORB/OrbRegionDemoRoot';
// start up pages
import StartTips from '../routes/ORBFirstTimeVisitPages/StartTips';

function RouteManager() {
  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Routes>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank" element={<BlankPage />} />

          {/* redirect to Auth0 */}
          <Route path="/login" element={<LoginPage />} />

          {/* ORB Demo Pages */}
          <Route
            path="/orb-region"
            element={
              <OrbRegionDemoRoot>
                <OrbRegionDemoPage />
              </OrbRegionDemoRoot>
            }
          />

          <Route
            path="/"
            element={
              <AppLayout>
                <IndexRedirectHandler />
              </AppLayout>
            }
          />

          <Route
            path="/teacherHome/reporting/:orgId?/classroom/:classroomId?/student/:studentId?"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbClassReportPage />
                </ORBLayout>
              </AppLayout>
            }
          />

          <Route
            path="/teacherHome/reporting/:orgId?/classroom/:classroomId?"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbClassReportPage />
                </ORBLayout>
              </AppLayout>
            }
          />

          <Route
            path="/teacherHome/reporting/:orgId?/school?"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbClassReportPage />
                </ORBLayout>
              </AppLayout>
            }
          />

          <Route
            path="/teacherHome/reporting/:orgId?/multi-school-report?"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbClassReportPage />
                </ORBLayout>
              </AppLayout>
            }
          />

          <Route
            path="/teacherHome/library"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbTeacherResourcesPage />
                </ORBLayout>
              </AppLayout>
            }
          />

          <Route
            path="/teacherHome/offlineApp"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbOfflineAppPage />
                </ORBLayout>
              </AppLayout>
            }
          />

          <Route
            path="/teacherHome/contactPreferences"
            element={
              <AppLayout>
                <ORBLayout>
                  <OrbTeacherContactPreferencesPage />
                </ORBLayout>
              </AppLayout>
            }
          />

          {/* The token param can be one of:
            - Blank string for Full Registration.
            - 'finish' for completing a Partial Registration of existing or legacy user.
            - Any userToken string for completing a Partial Registration via an email link with a token. */}

          <Route
            path="/register/:token?"
            element={<AppLayout>{protectedRoute(RegisterPage, false, Object.keys(userRoles), '/myProfile')}</AppLayout>}
          />
          <Route
            path="/invitation"
            element={
              <AppLayout>
                <InvitationUpdatePage />
              </AppLayout>
            }
          />
          <Route
            path="/reset-password/:token"
            element={
              <AppLayout>{protectedRoute(ResetPassword, false, Object.keys(userRoles), '/myProfile')}</AppLayout>
            }
          />
          <Route
            path="/reset-password"
            element={
              <AppLayout>{protectedRoute(RequestPasswordReset, false, Object.keys(userRoles), '/myProfile')}</AppLayout>
            }
          />

          {/* List Pages */}
          {/* Org page url for most people will be /mySchool but for admins it will be /org/xxx */}
          <Route
            path="/mySchool/:tabName?/:panelName?"
            element={
              <AppLayout>
                {protectedRoute(
                  MySchoolPage,
                  false,
                  [userRoles.USER, userRoles.LEARNER],
                  '/myProfile',
                  false,
                  false,
                  'MY_ORG'
                )}
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId/user/:userId/:tabName?/:panelName?"
            element={
              <AppLayout>
                {protectedRoute(UserProfileInit, false, [userRoles.USER, userRoles.LEARNER], '/myProfile')}
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId/complete-registration"
            element={
              <AppLayout>
                <LegacyRegisterOrgPage />
              </AppLayout>
            }
          />

          {/* Download Signin Card Process */}
          <Route
            path="/org/:orgId/batchId/:batchId/records/:records"
            element={
              <AppLayout>
                <DownloadSigninCardProcess />
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId/class/:classroomId/:tabName?/:panelName?/:itemId?/:learnerId?"
            element={
              <AppLayout>
                {protectedRoute(ClassroomPage, false, [userRoles.USER, userRoles.LEARNER], '/myProfile')}
              </AppLayout>
            }
          />
          <Route
            path="/org/:orgId/:tabName?/:panelName?"
            element={<AppLayout>{withAuthorization(MySchoolPage, limitToOrgRoles(), '/')}</AppLayout>}
          />
          <Route
            path="/myProfile/:tabName?/:panelName?/:itemId?"
            element={
              <AppLayout>
                <MyProfilePage />
              </AppLayout>
            }
          />

          {/* Create Org Page */}
          <Route
            path="/createOrganisation"
            element={
              <AppLayout>
                <CreateOrganisation />
              </AppLayout>
            }
          />

          {/* Support pages */}
          <Route
            path="/support/articles/:pageName?"
            element={
              <AppLayout>
                <SupportArticlePage />
              </AppLayout>
            }
          />
          <Route
            path="/support/:tabName?"
            element={
              <AppLayout>
                <SupportHomePage />
              </AppLayout>
            }
          />

          <Route
            path="/redeem"
            element={
              <AppLayout>
                {protectedRoute(RedeemCodePage, false, Object.keys(userRoles), '/myProfile/learningMaterial/addCode')}
              </AppLayout>
            }
          />
          <Route
            path="/redeem/code"
            element={
              <AppLayout>
                <RedeemCodeSuccessPage />
              </AppLayout>
            }
          />

          {/* Decline Org Invitation */}
          <Route
            path="/decline-invite/:token"
            element={
              <AppLayout>
                <DeclineOrgInvitation />
              </AppLayout>
            }
          />

          {/* Show Invitations */}
          <Route
            path="/showInvitations"
            element={
              <AppLayout>
                <ConnectedBannerInvitation />
              </AppLayout>
            }
          />
          <Route
            path="/studentAnswerView/orgId/:orgId/class/:classroomId/:itemId/:learnerId/:bid/:uId/:activityId"
            element={
              <AppLayout>
                <StudentAnswerViewPage />
              </AppLayout>
            }
          />
          <Route
            path="/orb-start-tips/:step?"
            element={
              <AppLayout>
                <StartTips />
              </AppLayout>
            }
          />

          {/* 404 */}
          <Route
            path="*"
            element={
              <AppLayout>
                <NotFoundView />
              </AppLayout>
            }
          />
        </Routes>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
